
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import useVuelidate from "@vuelidate/core";
import { helpers,required } from "@vuelidate/validators";

export interface Data {
  items: [];
  selectedItem?: { id: string; effectiveDate: Date };
  createNewMode: boolean;
}
export default defineComponent({
  name: "RehabCRUDPage",
  components: {},
  setup() {
    const url = "api/v1/rehabs";

    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      items: [],
      selectedItem: undefined,
      createNewMode: true,
    });

    data.selectedItem = {
      id: "",
      effectiveDate: new Date(),
    };

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Rehabs");
      await getAll();
    });

    async function switchMode() {
      data.createNewMode = true;
      data.selectedItem = {
        id: "",
        effectiveDate: new Date(),
      };
    }

    async function getAll() {
      let request = {
        keyword: "",
        pageNumber: 0,
        pageSize: 100,
        orderBy: ["id"],
      };
      await ApiService.post(url + "/search", request as never).then((res) => {
        data.items = res.data.data;
        //TODO DEBT
        data.items.forEach((item: any) => {
          item.effectiveDate = new Date(item.effectiveDate);
          item.termDate = new Date(item.effectiveDate);
        });
      });
    }

    async function addItem() {
      const result = await v$.value.$validate();
      if (result) {
        await ApiService.post(url, data.selectedItem as never).then(
          async (): Promise<void> => {
            await getAll();
            data.createNewMode = false;
          }
        );
      }
    }

    function selectItem(item) {
      data.selectedItem = item;
      data.createNewMode = false;
    }

    async function updateItem() {
      const result = await v$.value.$validate();
      if (result) {
        if (data.selectedItem) {
          await ApiService.put(
            url + "/" + data.selectedItem.id,
            data.selectedItem as never
          ).then();
        }
      }
    }

    async function deleteItem() {
      if (data.selectedItem) {
        await ApiService.delete(url + "/" + data.selectedItem?.id).then(
          async (): Promise<void> => {
            await getAll();
          }
        );
      }
    }

    const providerRules = {
      selectedItem: {
        name: { required:helpers.withMessage("Required", required) },
        address: { required:helpers.withMessage("Required", required) },
        address2: { required:helpers.withMessage("Required", required) },
        city: { required:helpers.withMessage("Required", required) },
        state: { required:helpers.withMessage("Required", required) },
        zip: { required:helpers.withMessage("Required", required) },
        country: { required:helpers.withMessage("Required", required) },
        taxId: { required:helpers.withMessage("Required", required) },
        emrLinkSequenceId: {required:helpers.withMessage("Required", required)},
      },
    };

    const v$ = useVuelidate(providerRules, data as never);

    return {
      organizationId,
      data,
      addItem,
      selectItem,
      updateItem,
      deleteItem,
      switchMode,
      v$,
    };
  },
});
